<template>
    <div>
        <div v-if="orgInvitations.length && !fetchingOrgInvitations">
            <div class="d-flex flex-wrap align-center">
                <div v-for="invitationType in availableInvitationTypes" :key="invitationType">
                    <v-checkbox class="mx-4" v-model="invitationsSwitchData[invitationType]">
                        <template v-slot:label>
                            <div>
                                <v-icon class="mr-1" small :color="getMailIconColor(invitationType)">mail</v-icon>
                                <span class="secondary--text caption text-uppercase">{{ invitationAndRequestStatusTypes[invitationType] }}</span>
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <v-spacer />
                <v-text-field v-model="invitationEmailSearch" autofocus append-icon="search" placeholder="Filter" outlined dense hide-details></v-text-field>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-4" v-on="on" @click="$store.dispatch('orgStore/fetchOrgInvitations', $route.params.oid)" text icon>
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </div>
            <v-divider class="mb-5"></v-divider>
            <v-data-iterator :items="filteredInvitationsList">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="index">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center" justify="space-between">
                                <v-col cols="1">
                                    <v-icon :color="getMailIconColor(getInvitationStatus(item))" x-large>mail</v-icon>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.requestee_email }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div class="d-flex flex-column">
                                                <div class="caption mt-1" v-if="item.notification_timestamp">
                                                    <span class="font-weight-bold">Sent on:</span>
                                                    {{ item.notification_timestamp | dateTimeToHuman }}
                                                </div>
                                                <div
                                                    class="caption mt-1 mb-0"
                                                    v-if="getInvitationStatus(item) === invitationAndRequestStatusTypes.REVOKED && item.revocation_timestamp">
                                                    <span class="font-weight-bold">Revoked on:</span>
                                                    {{ item.revocation_timestamp | dateTimeToHuman }}
                                                </div>
                                                <div
                                                    v-if="
                                                        getInvitationStatus(item) === invitationAndRequestStatusTypes.ACCEPTED &&
                                                        invitationOrRequestHasTimestamp(item)
                                                    "
                                                    class="mt-1 caption">
                                                    <div>
                                                        <span class="font-weight-bold">Accepted on:</span>
                                                        {{ item.invitation_responses[0].timestamp | dateTimeToHuman }}
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="
                                                        getInvitationStatus(item) === invitationAndRequestStatusTypes.REJECTED &&
                                                        invitationOrRequestHasTimestamp(item)
                                                    "
                                                    class="mt-1 caption">
                                                    <div>
                                                        <span class="font-weight-bold">Rejected on:</span>
                                                        {{ item.invitation_responses[0].timestamp | dateTimeToHuman }}
                                                    </div>
                                                </div>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <div v-for="(role, index) in item.roles" :key="index" class="d-flex flex-column">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-if="role.role_type === invitationAndRequestRoleTypes.ORG_ROLE" v-on="on">
                                                            <ComponentWithStyledBorder>
                                                                <div class="d-flex align-center pa-1">
                                                                    <v-icon v-if="role.Name === roleTypes.ORG_MANAGER">corporate_fare</v-icon>
                                                                    <v-icon v-else-if="role.Name === roleTypes.ORG_FACULTY">supervisor_account</v-icon>
                                                                    <v-icon v-else>person</v-icon>
                                                                    <span class="ml-1">{{ role.Name }}</span>
                                                                </div>
                                                            </ComponentWithStyledBorder>
                                                        </div>
                                                    </template>
                                                    <span v-if="role.Name === 'N/A'">The target object has been deleted</span>
                                                    <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE">
                                                        This role will overwrite the current user role, even if the current role is more powerful that this one.
                                                    </span>
                                                    <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE_IF_LOWER">
                                                        This role will overwrite the current role only if the current role is less powerful than this one.
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col v-if="isOrgManager" cols="1">
                                    <div
                                        v-if="
                                            getInvitationStatus(item) === invitationAndRequestStatusTypes.PENDING ||
                                            getInvitationStatus(item) === invitationAndRequestStatusTypes.CREATED
                                        "
                                        class="d-flex align-center">
                                        <div>
                                            <InvitationActionsDialog
                                                :successFetchAction="
                                                    () => {
                                                        $store.dispatch('orgStore/fetchOrgInvitations', $route.params.oid)
                                                    }
                                                "
                                                :invitationInfo="item"
                                                :actionType="actionTypes.RESEND_INVITATION"></InvitationActionsDialog>
                                        </div>
                                        <div>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <copy-to-clipboard
                                                            :textToCopy="item.link"
                                                            buttonClass="caption font-weight-bold"
                                                            buttonColor="secondary"
                                                            buttonName="copy to clipboard"
                                                            :iconButton="true"
                                                            :isSmall="true"></copy-to-clipboard>
                                                    </div>
                                                </template>
                                                <span>Copy invitation link</span>
                                            </v-tooltip>
                                        </div>
                                        <div>
                                            <InvitationActionsDialog
                                                :successFetchAction="
                                                    () => {
                                                        $store.dispatch('orgStore/fetchOrgInvitations', $route.params.oid)
                                                    }
                                                "
                                                :invitationInfo="item"
                                                :actionType="actionTypes.REVOKE_INVITATION"></InvitationActionsDialog>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </div>
                    <div v-if="isOrgManager">
                        <invite-btn @goToInvite="$emit('goToInvite', { value: true })" />
                    </div>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                </template>
            </v-data-iterator>
        </div>
        <div v-else-if="!orgInvitations.length && !fetchingOrgInvitations">
            <v-banner single-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">info</v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold secondary--text">No invitations found</span>
                    <p>
                        Currently this organization has no invitation history. Consider inviting new users via the
                        <span class="text-uppercase font-weight-bold secondary--text">
                            <v-icon small color="primary">add</v-icon>
                            invite
                        </span>
                        tab above.
                    </p>
                </div>
            </v-banner>
        </div>
        <div v-else-if="fetchingOrgInvitations">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6">
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
import { sortArray } from '@/utils'
import InviteBtn from '@/components/InviteBtn.vue'
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const InvitationActionsDialog = () => import('@/modules/invitations/components/InvitationsActionsDialog')
const ComponentWithStyledBorder = () => import('@/components/ComponentWithStyledBorder')
export default {
    mixins: [enumsData, invitationsAndRequests],
    components: {
        CopyToClipboard,
        InvitationActionsDialog,
        ComponentWithStyledBorder,
        InviteBtn
    },
    data() {
        return {
            search: '',
            invitationsSwitchData: {
                ACCEPTED: true,
                PENDING: true,
                REJECTED: true,
                REVOKED: true,
                EXPIRED: true,
                BROKEN: true,
                CREATED: true
            },
            invitationEmailSearch: ''
        }
    },
    computed: {
        ...mapState('orgStore', ['orgInvitations', 'fetchingOrgInvitations']),
        ...mapGetters('orgStore', ['isOrgManager']),
        availableInvitationTypes: function () {
            const invitationTypes = []
            if (this.orgInvitations && this.orgInvitations.length) {
                this.orgInvitations.forEach(invitation => {
                    const invitationType = this.getInvitationStatus(invitation)
                    if (!invitationTypes.includes(invitationType)) {
                        invitationTypes.push(invitationType)
                    }
                })
                return invitationTypes
            }
            return []
        },
        displayedInvitationTypes: function () {
            const filteredInvitations = Object.values(this.invitationAndRequestStatusTypes).filter(
                value => this.availableInvitationTypes.includes(value) && this.invitationsSwitchData[value]
            )
            return filteredInvitations
        },
        filteredInvitationsList: function () {
            const filterByStatusType = this.orgInvitations.filter(invitation => this.displayedInvitationTypes.includes(this.getInvitationStatus(invitation)))
            if (!this.invitationEmailSearch) {
                const sortedData = sortArray(filterByStatusType, 'invid', 'descending', false)
                return sortedData
            } else {
                const filteredData = filterByStatusType.filter(invitation => invitation.requestee_email.includes(this.invitationEmailSearch.toLowerCase()))
                const sortedData = sortArray(filteredData, 'invid', 'descending', false)
                return sortedData
            }
        }
    },
    watch: {
        availableInvitationTypes: function (nextVal) {
            if (nextVal.length && nextVal.includes(this.invitationAndRequestStatusTypes.PENDING)) {
                this.invitationsSwitchData = {
                    ACCEPTED: false,
                    PENDING: true,
                    REJECTED: false,
                    REVOKED: false,
                    EXPIRED: false,
                    BROKEN: false,
                    CREATED: false
                }
            }
        }
    }
}
</script>
